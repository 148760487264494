<mat-expansion-panel
  class="mx-2 my-1"
  (opened)="panelOpenState = true"
  (closed)="panelOpenState = false"
  [(expanded)]="expanded"
>
  <mat-expansion-panel-header>
    <mat-panel-title> {{ location().title }} </mat-panel-title>
  </mat-expansion-panel-header>
  @for (child of location().children; track $index) {
    @if (child.title) {
      @if (child.children) {
        <app-sidebar-category [location]="child"></app-sidebar-category>
      } @else {
        <app-sidebar-item [location]="child"></app-sidebar-item>
      }
    }
  }
</mat-expansion-panel>
