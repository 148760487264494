<mat-sidenav-container style="height: 100vh">
  <mat-sidenav mode="side" opened>
    <mat-card class="h-full">
      <app-sidebar class="flex flex-col h-full"> </app-sidebar>
    </mat-card>
  </mat-sidenav>
  <mat-sidenav-content class="p-3">
    @defer {
      <router-outlet></router-outlet>
    } @placeholder {
      <p>Loading...</p>
    }
  </mat-sidenav-content>
</mat-sidenav-container>
