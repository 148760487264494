import { Component, OnInit, computed, inject, input } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { Route, Router } from '@angular/router';
import { SidebarItemComponent } from '../sidebar_item/sidebar_item.component';

@Component({
  selector: 'app-sidebar-category',
  imports: [MatExpansionModule, SidebarItemComponent],
  templateUrl: './sidebar_category.component.html',
  styleUrl: './sidebar_category.component.css',
})
export class SidebarCategoryComponent implements OnInit {
  router = inject(Router);
  readonly location = input.required<Route>();
  expanded = false;
  panelOpenState = false;

  isCategory = computed(() => {
    const location = this.location();
    return (
      location.children !== undefined &&
      location.children.filter((child) => child.data?.['hide'] ?? false)
        .length > 0
    );
  });

  ngOnInit(): void {
    this.location().children?.forEach((child) => {
      if (!child.path) return;
      const location = this.location();
      if (!location.path) return;

      child.path = location.path + '/' + child.path;
    });
    this.router.events.subscribe(() => {
      const location = this.location();
      if (location.path) {
        this.expanded = this.router.url.includes(location.path);
      }
    });
  }
}
