import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { CurrentUserService } from 'app/services/user.service';

@Component({
  selector: 'app-logout-button',
  imports: [MatButtonModule],
  templateUrl: './logout_button.component.html',
  styleUrl: './logout_button.component.css',
})
export class LogoutButtonComponent {
  private userService = inject(CurrentUserService);

  async logout() {
    await this.userService.logout();
  }
}
