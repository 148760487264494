import { Routes } from '@angular/router';
import { AuthGuard } from './utils/keycloak_guard.guard';

const protected_routes: Routes = [
  {
    title: 'Panel Główny',
    path: 'dashboard',
    loadComponent: () =>
      import('./components/screens/dashboard/dashboard.component').then(
        (m) => m.DashboardComponent,
      ),
  },
  {
    title: 'Produkty',
    path: 'products',
    loadComponent: () =>
      import('./components/screens/products/products.component').then(
        (m) => m.ProductsComponent,
      ),
  },
  {
    title: 'Członkostwo',
    path: 'payments',
    children: [
      {
        path: ':id',
        data: { hide: true },
        loadComponent: () =>
          import(
            './components/screens/payments/payment_details/payment_details.component'
          ).then((m) => m.PaymentDetailsComponent),
      },
      {
        path: '',
        data: { hide: true },
        loadComponent: () =>
          import('./components/screens/payments/payments.component').then(
            (m) => m.PaymentsComponent,
          ),
      },
    ],
  },
  {
    title: 'Zamówienia',
    path: 'orders',
    loadComponent: () =>
      import('./components/screens/products/products.component').then(
        (m) => m.ProductsComponent,
      ),
  },
  // {
  //   title: 'Analiza pliku',
  //   path: 'file',
  //   component: FileComponentComponent,
  // },
  {
    title: 'Ustawienia',
    path: 'settings',
    data: { isSeparator: true },
    loadComponent: () =>
      import('./components/screens/settings/settings.component').then(
        (m) => m.SettingsComponent,
      ),
  },
  // Support
  {
    title: 'Wsparcie',
    path: 'support',
    children: [
      {
        title: 'Najczęściej zadawane pytania',
        path: 'faq',
        loadComponent: () =>
          import('./components/screens/support/faq/faq.component').then(
            (m) => m.FaqComponent,
          ),
      },
      {
        title: 'Kontakt',
        path: 'contact',
        loadComponent: () =>
          import('./components/screens/support/contact/contact.component').then(
            (m) => m.ContactComponent,
          ),
      },
      {
        title: 'Wystaw Opinię',
        path: 'feedback',
        loadComponent: () =>
          import(
            './components/screens/support/feedback/feedback.component'
          ).then((m) => m.FeedbackComponent),
      },
    ],
  },
  // Legal
  {
    title: 'Prawne',
    path: 'legal',
    children: [
      {
        title: 'Polityka prywatności',
        path: 'privacy-policy',
        loadComponent: () =>
          import(
            './components/screens/legal/privacy_policy/privacy_policy.component'
          ).then((m) => m.PrivacyPolicyComponent),
      },
      {
        title: 'Warunki użytkowania',
        path: 'terms-of-service',
        loadComponent: () =>
          import(
            './components/screens/legal/terms_of_service/terms_of_service.component'
          ).then((m) => m.TermsOfServiceComponent),
      },
      {
        title: 'Polityka Ciasteczek',
        path: 'cookie-policy',
        loadComponent: () =>
          import(
            './components/screens/legal/cookie_policy/cookie_policy.component'
          ).then((m) => m.CookiePolicyComponent),
      },
    ],
  },

  // Redirects
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
];

export const routes: Routes = [
  {
    children: protected_routes,
    canActivate: [AuthGuard],
    path: '',
  },
];
