<button #primary mat-button style="display: none"></button>
<div class="flex content-center justify-between p-4">
  <div class="fs-1 title text-3xl">Succesell</div>
  @if (isBackendConnected !== undefined) {
    <mat-icon
      (click)="connectionService.ping()"
      class="m-1 me-4"
      style="scale: 1.5"
      [color]="isBackendConnected() ? 'primary' : 'warn'"
    >
      trending_up</mat-icon
    >
  } @else {
    <mat-icon class="m-1 me-4" style="scale: 1.5">trending_up</mat-icon>
  }
</div>
