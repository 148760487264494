import { Component, OnInit, inject } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterOutlet } from '@angular/router';
import { environment } from 'environment';
import { KeycloakService } from 'keycloak-angular';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { CurrentUserService } from './services/user.service';

@Component({
  selector: 'app-root',
  imports: [RouterOutlet, SidebarComponent, MatSidenavModule, MatCardModule],
  providers: [],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})
export class AppComponent implements OnInit {
  private iconRegistry = inject(MatIconRegistry);
  private userService = inject(CurrentUserService);
  private keycloakService = inject(KeycloakService);
  private dialog = inject(MatDialog);
  public environment = environment;

  title = 'WebData';

  async ngOnInit() {
    this.iconRegistry.setDefaultFontSetClass('material-symbols-rounded');

    await this.userService.fetchUser();
  }
}
