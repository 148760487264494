<div
  class="mx-2 mb-4"
  [routerLink]="location().path"
  routerLinkActive=""
  #rla="routerLinkActive"
>
  @if (rla.isActive) {
    <button mat-flat-button class="w-full">
      {{ location().title }}
    </button>
  } @else {
    <button mat-button class="w-full">
      {{ location().title }}
    </button>
  }
</div>
