<app-sidebar-header></app-sidebar-header>
<mat-accordion class="flex grow flex-col">
  @for (route of routes; track $index) {
    @if (route.title && route.path) {
      @if (route.data?.["isSeparator"]) {
        <div class="grow"></div>
        <app-logout-button></app-logout-button>
      }
      @if (isCategory(route)) {
        <app-sidebar-category [location]="route" class="mx-2 mb-2">
        </app-sidebar-category>
      } @else {
        <app-sidebar-item
          [routerLink]="route.path"
          [location]="route"
          routerLinkActive
          #rla="routerLinkActive"
        ></app-sidebar-item>
      }
    }
  }
</mat-accordion>
