import { Component, OnInit } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { Route, RouterLink, RouterLinkActive } from '@angular/router';
import { routes } from 'app/app.routes';
import { LogoutButtonComponent } from './logout_button/logout_button.component';
import { SidebarCategoryComponent } from './sidebar_category/sidebar_category.component';
import { SidebarHeaderComponent } from './sidebar_header/sidebar_header.component';
import { SidebarItemComponent } from './sidebar_item/sidebar_item.component';

@Component({
  selector: 'app-sidebar',
  imports: [
    SidebarHeaderComponent,
    LogoutButtonComponent,
    SidebarItemComponent,
    RouterLinkActive,
    RouterLink,
    SidebarCategoryComponent,
    MatExpansionModule,
  ],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.css',
})
export class SidebarComponent implements OnInit {
  panelOpenState = false;
  routes: Route[] = [];

  isCategory(location: Route) {
    return (
      location.children &&
      location.children.filter((child) => !(child.data?.['hide'] ?? false))
        .length > 0
    );
  }

  ngOnInit(): void {
    this.routes = routes[0].children ?? [];
  }
}
