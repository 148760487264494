import { APP_INITIALIZER, ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';

import { provideHttpClient, withFetch } from '@angular/common/http';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { environment } from 'environment';
import { KeycloakService } from 'keycloak-angular';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { Configuration } from 'src/api';
import { routes } from './app.routes';
import { defaultTooltipConfig } from './utils/tooltip_config.provider';

function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        realm: environment.keycloak.realm,
        url: environment.keycloak.authority,
        clientId: environment.keycloak.clientId,
      },
      initOptions: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri:
          window.location.origin + '/assets/silent-check-sso.html',
      },
    });
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimationsAsync(),
    provideHttpClient(withFetch()),
    // { provide: BASE_PATH, useValue: environment.default_url },
    defaultTooltipConfig,
    {
      provide: Configuration,
      useFactory: () =>
        new Configuration({
          withCredentials: true,
          basePath: environment.default_url,
        }),
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
    KeycloakService,
    [provideCharts(withDefaultRegisterables())],
  ],
};
