import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { ConnectionStatus } from 'app/models/connection_status.enum';
import { HealthcheckService } from 'src/api/api/healthcheck.service';
@Injectable({
  providedIn: 'root',
})
export class ConnectionService {
  public connectionStatusSignal = signal(ConnectionStatus.DISCONNECTED);
  private currentAttempt = 0;
  private healthcheckClient = inject(HealthcheckService);
  constructor() {
    this.ping();
  }

  ping() {
    this.currentAttempt++;
    this.connectionStatusSignal.set(ConnectionStatus.CONNECTING);
    this.healthcheckClient.healthCheck().subscribe({
      next: () => {
        this.connectionStatusSignal.set(ConnectionStatus.CONNECTED);
      },
      error: (error: HttpErrorResponse) => {
        if (error.status == 200) {
          this.connectionStatusSignal.set(ConnectionStatus.CONNECTED);
          return;
        }
        this.connectionStatusSignal.set(ConnectionStatus.ERRORED);
      },
    });
  }
}
