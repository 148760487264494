import { Component, input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { Route, RouterLink, RouterLinkActive } from '@angular/router';

@Component({
  selector: 'app-sidebar-item',
  imports: [MatCardModule, MatButtonModule, RouterLink, RouterLinkActive],
  templateUrl: './sidebar_item.component.html',
  styleUrl: './sidebar_item.component.css',
})
export class SidebarItemComponent {
  readonly location = input.required<Route>();
}
