import {
  MAT_TOOLTIP_DEFAULT_OPTIONS,
  MatTooltipDefaultOptions,
} from '@angular/material/tooltip';

export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 0,
  hideDelay: 200,
  touchendHideDelay: 1000,
};

export const defaultTooltipConfig = {
  provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
  useValue: myCustomTooltipDefaults,
};
