import {
  AfterViewInit,
  Component,
  computed,
  inject,
  viewChild,
} from '@angular/core';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ConnectionStatus } from 'app/models/connection_status.enum';
import { ConnectionService } from 'app/services/connection.service';
import { SettingsService } from 'app/services/settings.service';

@Component({
  selector: 'app-sidebar-header',
  imports: [MatIconModule, MatButtonModule],
  templateUrl: './sidebar_header.component.html',
  styleUrl: './sidebar_header.component.css',
})
export class SidebarHeaderComponent implements AfterViewInit {
  connectionService = inject(ConnectionService);
  isBackendConnected = computed(() => {
    return (
      this.connectionService.connectionStatusSignal() >=
      ConnectionStatus.CONNECTED
    );
  });
  readonly primary = viewChild<MatButton>('primary');
  settingsService = inject(SettingsService);
  ngAfterViewInit() {
    const primary = this.primary();
    if (!primary) return;
    this.settingsService.currentColorSignal.set(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      getComputedStyle(primary._elementRef.nativeElement).color,
    );
  }
}
